.container[data-pageurl="/contacts/"], .container[data-pageurl="/ads-spent"] {
    max-width: 1920px;
}
@media screen and (min-width:1200px) {
    .filterpopout {
        width: 20%;
        position: absolute;
        top: 80px;
        right: 0;
    }
    .contact_filter_input {   
        width: 80%;
    }
    .filterpopout .multinewfilter {
        width: 190px;
        margin: auto;
        margin-right: 0;
    }
}    
.disabledfieldStatus {
    cursor: not-allowed;
    transition: 0.3s all;
    user-select: none;
}
.disabledfieldStatus .disableDomainBtn {
    opacity: 0.7;
    pointer-events: none;
    cursor: not-allowed;
}

