.textcopiedtooltip {
    font-size: 10px;
    padding: 2px 8px;
    transition: all 0.1s;
    background-color: black;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    position: absolute;
    z-index: 1;
    bottom: 3px;
    left: 120%;
    opacity: 0;
}
.textcopiedtooltip::after {
    content: "";     
    position: absolute;
    top: 25%;
    left: 0;
    margin-left: -9px;
    border-width: 5px;
    border-style: solid;
    border-color: black transparent transparent transparent;
    transform: rotate(90deg);
  }
  .textcopiedtooltip.fadeincopytooltip {
    opacity: 1;
  }