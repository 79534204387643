// 3rd-party Plugins
@import "~highlight.js/styles/googlecode.css";
@import "~perfect-scrollbar/css/perfect-scrollbar.css";

// Theme Styles
@import "./_metronic/_assets/sass/style.react.scss";

// Default Layout themes
@import "./_metronic/_assets/sass/themes/layout/header/base/light.scss";
@import "./_metronic/_assets/sass/themes/layout/header/menu/light.scss";
@import "./_metronic/_assets/sass/themes/layout/brand/dark.scss";
@import "./_metronic/_assets/sass/themes/layout/aside/dark.scss";

// Header themes
// Light
//@import "./_metronic/_assets/sass/themes/layout/header/base/light.scss";
// Dark
// @import "./_metronic/_assets/sass/themes/layout/header/base/dark.scss";

// Header Menu themes
// Light
//@import "./_metronic/_assets/sass/themes/layout/header/menu/light.scss";
// Dark
// @import "./_metronic/_assets/sass/themes/layout/header/menu/dark.scss";

// Brand themes
.brand-dark {
  @import "./_metronic/_assets/sass/themes/layout/brand/dark.scss";
}
// Light
.brand-light {
  @import "./_metronic/_assets/sass/themes/layout/brand/light.scss";
}

// Aside themes
// Dark
//@import "./_metronic/_assets/sass/themes/layout/aside/dark.scss";
// Light
// @import "./_metronic/_assets/sass/themes/layout/aside/light.scss";

// .example-highlight {
//   padding: 0rem 1.5rem 1.75rem !important;
// }

.example-highlight > pre {
  background: none transparent !important;
  margin: 0 !important;
  padding-top: 0.5rem;
  code {
    overflow-y: auto;
    display: block;

    span {
      background: none transparent !important;
    }
  }
}

.json > pre {
  background: none #fff !important;
}

.example-code > .json {
  background: none #fff !important;
}

.symbol.symbol-45 > svg {
  width: 100%;
  max-width: 45px;
  height: 45px;
}

.react-bootstrap-table {
  overflow-x: auto;
}

.react-bootstrap-table {
  th {
    outline: none;

    &.sortable {
      .svg-icon-sort {
        opacity: 0;
      }

      &:hover {
        cursor: pointer;

        .svg-icon-sort {
          opacity: 1;
        }
      }
    }
  }

  .table.table-head-custom thead tr {
    .sortable-active {
      color: $primary !important;
    }
  }
}

.import-page {
  .MuiAlert-message {
    width: 100%;
  }
}

.avatar {
  vertical-align: middle;
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

// .nav-link:focus {
//     outline: none;
// }

.ad-spent {
  td,
  th {
    margin: 0;
    border: 1px solid grey;
    white-space: nowrap;
    border-top-width: 0px;
    input {
      width: 95px;
    }
  }

  div {
    width: 95%;
    overflow-x: scroll;
    margin-left: 5em;
    padding: 0;
  }

  .headcol {
    position: absolute;
    width: 7em;
    left: 0;
    top: auto;
    border-top-width: 1px;
    background-color: green;
    color: white;
  }
}


.graph-container {
  overflow-x: unset !important;
}

.white-box { 
  background: #fff;
  padding: 15px;
  margin-bottom: 30px;
  i {
    font-size: 35px;
    color: #7ace4c;
  }
}
.list-inline .counter {
  font-size: 24px;
  font-weight: 600;
  color: #7ace4c;
}

@media (max-width: 600px) {

  .ad-spent {
    div {
      width: 85%;
      overflow-x: scroll;
      margin-left: 5em;
      padding: 0;
    }
  }

  .live-chat {
    .apexcharts-xaxis-texts-g {
      > *:nth-child(even) {
        display: none;
      }
    }
  }
}

@media (max-width: 350px) {
  .live-chat {
    .apexcharts-xaxis-texts-g {
      > *:nth-child(3n + 1) {
        display: none;
      }
    }
  }
}

.login-form {
  // border: 1px solid;
  border-radius: 8px;
  padding: 22px;
  background-color: white !important;
}
.login::before {
  background: url("./images/login_back.jpg") no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;

  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0.8;
}

@media (min-width: 992px) {
  .login.login-1 .login-form {
    width: 100%;
    max-width: 500px !important;
  }
}

@media (min-width: 992px) {
  div[id$="license-text"] {
    margin-top: -445px !important;
  }
}

.switch-text {
  color: white !important;
  .MuiFormControlLabel-label {
    font-weight: 600;
    font-size: 14px;
  }
}

// .domain-drop{
//   width: 500px!important;
// }

//---------------Question Analysis-----------------
.count-inc-left {
  position: absolute;
  top: 33%;
  left: 100%;
  background-color: #e1f0ff !important;
  padding: 10px;
  span {
    font-weight: 600;
  }
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  width: 85px;
}

.good-inc-left {
  position: absolute;
  top: 50%;
  left: 100%;
  background-color: #e1f0ff !important;
  padding: 10px;
  span {
    font-weight: 600;
  }
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  width: 85px;
}

.bad-inc-left {
  position: absolute;
  top: 70%;
  left: 100%;
  background-color: #e1f0ff !important;
  padding: 10px;
  span {
    font-weight: 600;
  }
  width: 85px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

// .centered-graph{
//   .apexcharts-canvas{
//     margin: auto!important;
//   }
// }

.count-inc-right {
  position: absolute;
  top: 33%;
  right: 100%;
  background-color: #ffe2e5 !important;
  padding: 10px;
  span {
    font-weight: 600;
  }
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  width: 85px;
}

.good-inc-right {
  position: absolute;
  top: 50%;
  right: 100%;
  background-color: #ffe2e5 !important;
  padding: 10px;
  span {
    font-weight: 600;
  }
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  width: 85px;
}

.bad-inc-right {
  position: absolute;
  top: 70%;
  right: 100%;
  background-color: #ffe2e5 !important;
  padding: 10px;
  span {
    font-weight: 600;
  }
  width: 85px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

@media (max-width: 768px) {
  .comparision-div {
    display: none;
  }
  .count-inc-left {
    top: 100%;
    left: 10%;
    background-color: #e1f0ff !important;
    padding: 10px;
    span {
      font-weight: 600;
      font-size: 12px;
    }
    h6 {
      font-size: 12px;
    }
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
    border-top-right-radius: 0px;
    border-top-left-radius: 0px;
    width: 85px;
  }

  .good-inc-left {
    top: 100%;
    left: 43%;
    background-color: #e1f0ff !important;
    padding: 10px;
    span {
      font-weight: 600;
      font-size: 12px;
    }
    h6 {
      font-size: 12px;
    }
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
    border-top-right-radius: 0px;
    border-top-left-radius: 0px;
    width: 85px;
  }

  .bad-inc-left {
    top: 100%;
    left: 75%;
    background-color: #e1f0ff !important;
    padding: 10px;
    span {
      font-weight: 600;
      font-size: 12px;
    }
    h6 {
      font-size: 12px;
    }
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
    border-top-right-radius: 0px;
    border-top-left-radius: 0px;
    width: 85px;
  }

  .centered-graph {
    .apexcharts-canvas {
      margin: auto !important;
    }
  }

  .count-inc-right {
    top: -9%;
    left: 10%;
    background-color: #ffe2e5 !important;
    padding: 10px;
    span {
      font-weight: 600;
      font-size: 12px;
    }
    h6 {
      font-size: 12px;
    }
    border-top-right-radius: 5px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 0px;
    width: 85px;
  }

  .good-inc-right {
    top: -11%;
    left: 43%;
    background-color: #ffe2e5 !important;
    padding: 10px;
    span {
      font-weight: 600;
      font-size: 12px;
    }
    h6 {
      font-size: 12px;
    }
    border-top-right-radius: 5px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 0px;
    width: 85px;
  }

  .bad-inc-right {
    top: -11%;
    left: 75%;
    span {
      font-weight: 600;
      font-size: 12px;
    }
    h6 {
      font-size: 12px;
    }
    border-top-right-radius: 5px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 0px;
    width: 85px;
  }
}
@media (max-width: 500px) {
  .comparision-div {
    display: none;
  }
  .count-inc-left {
    top: 100%;
    left: 5%;
    span {
      font-weight: 600;
      font-size: 8px;
    }
    width: 62px;
  }

  .good-inc-left {
    top: 100%;
    left: 38%;
    span {
      font-weight: 600;
      font-size: 8px;
    }
    width: 65px;
  }

  .bad-inc-left {
    top: 100%;
    left: 70%;
    span {
      font-weight: 600;
      font-size: 8px;
    }
    width: 62px;
  }

  .centered-graph {
    .apexcharts-canvas {
      margin: auto !important;
    }
  }

  .count-inc-right {
    left: 5%;
    span {
      font-weight: 600;
      font-size: 8px;
    }
    width: 62px;
  }

  .good-inc-right {
    left: 38%;
    span {
      font-weight: 600;
      font-size: 8px;
    }
    width: 65px;
  }

  .bad-inc-right {
    left: 70%;
    span {
      font-weight: 600;
      font-size: 8px;
    }
    width: 62px;
  }
}

.react-form-builder-preview {
  min-height: 400px !important;
}
.MuiMenuItem-root {
  white-space: normal !important;
}

.preview-header {
  span {
    display: flex !important;
  }
}

.arrow-popup {
  position: relative;
  margin-top: 10px;
  overflow: visible !important;
  &::before {
    left: 9px;

    top: -9px;
    border-right: 9px solid transparent;
    border-left: 9px solid transparent;
    border-bottom: 9px solid white;
    position: absolute;
    display: inline-block;
    border-bottom-color: white;
    content: "";
  }
}

.expand-cell-header, .expand-cell{
  cursor: pointer;
}

@media (max-width: 768px){
.react-form-builder .react-form-builder-toolbar {
  margin-top: 20px;
  position: relative!important ;
  bottom: auto;

}
}
.multinewfilter{
  width: 240px;
}
